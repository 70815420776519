import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import Img from 'gatsby-image';
import { space, layout } from 'styled-system';
import Main from '../main';
import Text from '../text';
import OrderTracker from '../../images/order-tracker.svg';
import PhoneIcon from '../../images/hand-holding-phone.svg';
import AppStoreBadge from '../../images/app-store-badge.svg';
import PillPacket from '../../images/pill-packet.svg';
import { playStoreURL, appStoreURL } from '../../pages';

type DataProps = {
  data: any;
};

const StyledPhoneIcon = styled(PhoneIcon as AnyStyledComponent)`
  ${space}
  ${layout}
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const PrimarySplash = ({ data }: DataProps) => {
  return (
    <Main px={[3, 3, 5]} pt={4}>
      <Flex flexWrap="wrap">
        <Box
          p={[0, 1, 1]}
          mb={4}
          width={[1, 4 / 7, 4 / 7]}
          sx={{
            borderRight: '2px solid',
            borderColor: ['primary', 'white', 'white'],
          }}
        >
          <Text color="white" fontSize={[5, 6, 6]}>
            Prescriptions made easy!
          </Text>
          <Box pt={4} minHeight={160} paddingRight={[3, 3, '15%']} ml={-4}>
            <Flex>
              <Box>
                <StyledPhoneIcon width={['100px', '195px', '195px']} />
              </Box>
              <Box ml={5} pl={4}>
                <Text color="secondary" fontSize={5}>
                  tell us what you need...
                </Text>
                <Text color="white" fontSize={2}>
                  View your repeat prescriptions and order them directly to your pharmacy.
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box mt={[4, -5, -5]} minHeight={160}>
            <Text color="secondary" fontSize={5} mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              track your order...
            </Text>
            <Box mt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              <OrderTracker />
            </Box>
          </Box>
          <Box mt={4} mb={4} paddingLeft={[3, 3, '10%']} minHeight={160}>
            <Flex>
              <Box mt={[3, 0, 0]}>
                <PillPacket width={['90px', '238px', '238px']} />
              </Box>
              <Box mt={2} width="300px">
                <Text color="secondary" fontSize={5}>
                  and collect!
                </Text>
                <Text color="white" fontSize={2}>
                  we'll send you a notification when your medication is ready!
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          pt={3}
          pl={4}
          width={[1, 3 / 7, 3 / 7]}
          sx={{
            textAlign: ['center', 'left', 'left'],
          }}
        >
          <Flex height="100%" justifyContent="space-between" flexDirection="column">
            <Box>
              <Box
                mt={2}
                mb="32px"
                mx="auto"
                backgroundColor="secondary"
                width={['180px', '150px', '80%']}
                sx={{ borderRadius: '10px' }}
              >
                <StyledLink
                  href={`${process.env.GATSBY_WEB_APP_URL}/#/ways-to-register` ?? ''}
                  title="Link to mange my meds app registration"
                >
                  <Text color="primary" fontSize={4} mt={3} textAlign="center" padding="5px" fontFamily="bold">
                    Register
                  </Text>
                </StyledLink>
              </Box>
              <Box
                mt={2}
                mb="32px"
                mx="auto"
                backgroundColor="primary"
                width={['180px', '150px', '80%']}
                sx={{ borderRadius: '10px', border: '1px solid #83aeae' }}
              >
                <StyledLink
                  href={`${process.env.GATSBY_WEB_APP_URL}/#/login` ?? ''}
                  title="Link to mange my meds app sign in"
                >
                  <Text color="white" fontSize={4} textAlign="center" padding="5px" fontFamily="bold">
                    Log In
                  </Text>
                </StyledLink>
              </Box>
            </Box>
            <Flex flexWrap="wrap-reverse">
              <Box width={[1, 1, 1 / 2]} sx={{ textAlign: ['center', 'center', 'inherit'] }}>
                <Img
                  fixed={data.appleScreenshot1Image.childImageSharp.fixed}
                  style={{ zIndex: '1', marginBottom: '-3px' }}
                />
              </Box>
              <Box width={[1, 1, 1 / 2]} pl={[1, 1, 5]} pt={[3, 3, 6]}>
                <Text color="secondary" fontSize={5} textAlign="center" padding="5px">
                  Download
                </Text>
                <Text color="secondary" fontSize={4} textAlign="center" padding="5px" mt={-3}>
                  our app here!
                </Text>
                <Box mt={3} sx={{ textAlign: ['center', 'center', 'inherit'] }}>
                  <a href={appStoreURL} title="Link to iOS App Store for manage my meds app">
                    <AppStoreBadge />
                  </a>
                </Box>
                <Box mt={2} sx={{ textAlign: ['center', 'center', 'inherit'] }}>
                  <a href={playStoreURL} title="Link to Google Play Store for manage my meds app">
                    <Img fixed={data.googlePlayImage.childImageSharp.fixed} style={{ zIndex: '1' }} />
                  </a>
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Main>
  );
};

export default PrimarySplash;

import React from "react";
import { Box, Flex } from "rebass/styled-components";
import Main from "../main";
import Text from "../text";
import Icon from "../../images/icon.svg";
import Meds from "../../images/meds.svg";
import Clipboard from "../../images/clipboard.svg";
import Clock from "../../images/clock.svg";
import Add from "../../images/add.svg";
import Calendar from "../../images/calendar.svg";
import Secure from "../../images/secure.svg";
import Img from "gatsby-image";

type DataProps = {
  data: any;
};

const infoFlexMobile = "1 0 95%";
const infoFlexTablet = "1 0 45%";

const LightSplash = ({ data }: DataProps) => (
  <Main bg="primaryLightBg" display="flex" sx={{ flexWrap: "wrap" }}>
    <Box width={[1, 1, 3 / 4]} p={[1, 4, 4]}>
      <Flex
        flexWrap="wrap"
        sx={{
          borderBottom: "1px solid",
          borderColor: "primary",
          flexWrap: "wrap",
        }}
      >
        <Box display={["none", "block", "block"]}>
          <Icon />
        </Box>
        <Box mx={[0, 2, 4]}>
          <Text color="primary" fontSize={["20px", "25px", "40px"]}>
            A free service from Avicenna
          </Text>
          <Text
            color="primary"
            fontSize={["20px", "25px", "40px"]}
            ml={[0, 4, 6]}
          >
            to help you manage your meds.
          </Text>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="60px">
              <Meds />
            </Box>
            <Box ml={3} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                Simple to reorder
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                Reorder what you need with the click of a button.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="60px">
              <Clipboard />
            </Box>
            <Box ml={3} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                Connect to your GP and pharmacy!
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                Make appointments, message your GP practice and more…
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="60px">
              <Clock />
            </Box>
            <Box ml={4} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                Daily reminders
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                You can set reminders to help make sure you take your medication
                each day.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="60px">
              <Add />
            </Box>
            <Box ml={4} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                Add multiple users
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                Add family members and manage medications on their behalf.
                Easily switch between accounts.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="60px">
              <Calendar />
            </Box>
            <Box ml={3} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                No need to run out
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                You can set reminders to tell you when you need to re-order.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]} mt={5}>
          <Flex>
            <Box width="65px">
              <Secure />
            </Box>
            <Box ml={3} mr={3}>
              <Text color="primary" fontSize="28px" fontFamily="medium">
                Your data is secure
              </Text>
              <Text color="primary" fontSize={2} fontFamily="medium">
                It’s encrypted and we’ll never share your information with third
                parties.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
    <Box
      width={[1, 1, 1 / 4]}
      pt={4}
      sx={{ textAlign: ["center", "center", "inherit"] }}
    >
      <Img fixed={data.appleScreenshot2Image.childImageSharp.fixed} />
    </Box>
  </Main>
);

export default LightSplash;

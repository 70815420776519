import React, { ReactNode } from "react";
import { Box, Flex } from "rebass/styled-components";

import Text from "./text";

const Quote = ({ children }: { children: ReactNode }) => (
  <Flex px={[4, 0, 0]}>
    <Box
      as="span"
      color="white"
      fontSize={[4, "100px", "100px"]}
      marginTop={["10px", "35px", "35px"]}
      marginRight={["5px", "0", "0"]}
      sx={{ fontFamily: "Times", lineHeight: "10px" }}
    >
      &ldquo;&nbsp;
    </Box>
    <Text color="primary" fontSize={[4, 6, 6]}>
      {children}
      <Box
        as="span"
        color="white"
        fontSize={[4, "100px", "100px"]}
        verticalAlign="middle"
        sx={{ fontFamily: "Times", lineHeight: "10px" }}
      >
        &rdquo;
      </Box>
    </Text>
  </Flex>
);

export default Quote;
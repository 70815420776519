import React from "react";
import { Box, Flex } from "rebass/styled-components";
import Img from "gatsby-image";

import Main from "../main";
import Icon from "../../images/icon.svg";
import Text from "../text";
import AppStoreBadge from "../../images/app-store-badge.svg";
import Quote from "../quote";
import { appStoreURL, playStoreURL } from "../../pages";
import styled, { AnyStyledComponent } from "styled-components";
import { layout, space } from "styled-system";

type DataProps = {
  data: any;
};

const StyledIcon = styled(Icon as AnyStyledComponent)`
  ${space}
  ${layout}
`;

const LightestSplash = ({ data }: DataProps) => {
  return (
    <Main
      bg="primaryLightestBg"
      display="flex"
      sx={{
        flexWrap: "wrap-reverse",
      }}
      mb={["80px", "180px", "100px"]}
    >
      <Box
        width={[1, 1, 5 / 12]}
        pl={[2, 5, 5]}
        pr={[2, 3, 3]}
        my={[2, 2, 5]}
        sx={{ borderRight: "1px solid #83aeae" }}
      >
        <Flex pt={[0, 0, 5]} justifyContent={["center", "center", "flex-start"]}>
          <Img fixed={data.appleScreenshot3Image.childImageSharp.fixed} />
          <Box pl={3}>
            <StyledIcon mt={5} ml={2} />
            <Text fontSize={4} textAlign="center" mt={5}>Download</Text>
            <Text fontSize={3} textAlign="center">our app here</Text>
            <Box mt={5}>
              <a
                href={appStoreURL}
                title="Link to iOS App Store for manage my meds app"
              >
                <AppStoreBadge />
              </a>
            </Box>
            <Box mt={2}>
              <a
                href={playStoreURL}
                title="Link to Google Play Store for manage my meds app"
              >
                <Img
                  fixed={data.googlePlayImage.childImageSharp.fixed}
                  style={{ zIndex: "1" }}
                />
              </a>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box width={[1, 1, 7 / 12]} p={[2, 5, 5]}>
        <Box
          mt={4}
          sx={{
            maxWidth: ["100%", "100%", "500px"],
          }}
        >
          <Quote>Now I get my prescriptions on time, stress free.</Quote>
        </Box>
        <Box
          sx={{
            maxWidth: ["100%", "100%", "500px"],
            marginRight: "0",
            marginLeft: "auto",
          }}
          mt={4}
        >
          <Quote>So easy to use... five stars!</Quote>
        </Box>
        <Box
          mt={4}
          sx={{
            maxWidth: ["100%", "100%", "500px"],
          }}
        >
          <Quote>It helps me remember everything!</Quote>
        </Box>
      </Box>
    </Main>
  );
};

export default LightestSplash;
